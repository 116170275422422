<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        okText="确定"
        @ok="onout"
        :closable="false"
        :maskClosable="false"
        :visible="logvisible"
        :cancel-button-props="{ props: { disabled: true } }"
      >
        登录已失效，请前往重新登陆
      </a-modal>
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>基础配置</a-breadcrumb-item>
            <a-breadcrumb-item>公司单位</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="visible == false" class="Enter-top-right">
          <a-input-group style="width: 300px" compact>
            <a-select
              style="width: 90px"
              v-model="value"
              default-value="cs.name"
            >
              <a-select-option value="name"> 企业名称 </a-select-option>
              <a-select-option value="code"> 企业编号 </a-select-option>
              <a-select-option value="shortName"> 企业简称 </a-select-option>
              <a-select-option value="contactName"> 联系人 </a-select-option>
              <a-select-option value="contactMobile">
                联系人电话
              </a-select-option>
              <a-select-option value="state"> 状态 </a-select-option>
            </a-select>
            <a-select
              v-if="value == 'state'"
              @change="onSearch"
              style="width: 210px"
            >
              <a-select-option value="0"> 启用 </a-select-option>
              <a-select-option value="1"> 禁用 </a-select-option>
            </a-select>
            <a-input-search
              v-else
              placeholder="请输入搜索内容"
              style="width: 210px"
              v-model="key"
              @search="onSearch"
            />
          </a-input-group>
          <a-button
            style="margin: 0 15px"
            type="primary"
            @click.prevent="showDrawer"
          >
            新增公司
          </a-button>
          <a-button type="danger" @click.prevent="onDetele"> 删除 </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="onBack"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div>
          <span v-if="packagelist.packages.length > 0" class="packages"
            >当前为{{
              packagelist.packages.length > 0
                ? packagelist.packages[0].packageNameSnapshot +
                  '等' +
                  packagelist.packages.length +
                  '个套餐'
                : packagelist.packages[0].packageNameSnapshot
            }}-</span
          >项目总量:
          <span class="packages">{{
            packagelist.total + packagelist.usage
          }}</span
          >-已添加: <span class="packages">{{ packagelist.usage }}</span> -剩余:
          <span class="packages">{{
            packagelist.total - packagelist.total
          }}</span>
        </div>
        <div class="search-wrap">
          <div style="width: 100%">
            <div v-if="!visible">
              <a-table
                :row-selection="rowSelection"
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="enterpriselist"
                :customRow="handleClickRow"
                :rowClassName="rowClassName"
              >
                <span slot="status" slot-scope="text, record">
                  <a-switch
                    :checked="text == 0 ? true : false"
                    default-checked
                    @change="onchangestate(record)"
                  />
                </span>
                <a slot="action" slot-scope="text, record">
                  <a @click="onEdit(record)">编辑</a>
                </a>
              </a-table>
              <a-pagination
                v-model="params.current"
                :page-size="params.pageSize"
                show-quick-jumper
                :default-current="2"
                :total="total"
                @change="onpagesize"
              />
            </div>
            <div v-else>
              <a-form-model
                :model="form"
                ref="ruleForm"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="LOGO">
                  <div class="clearfix">
                    <a-upload
                      action=""
                      list-type="picture-card"
                      :file-list="form.logo"
                      :before-upload="beforeUpload"
                      @preview="handlePreview"
                      :customRequest="handleChange"
                      @change="handleChanges"
                    >
                      <div v-if="form.logo.length < 1">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">Upload</div>
                      </div>
                    </a-upload>
                    <a-modal
                      :visible="previewVisible"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <img
                        alt="example"
                        style="width: 100%"
                        :src="previewImage"
                      />
                    </a-modal>
                  </div>
                </a-form-model-item>
                <a-form-model-item prop="partyBAddress" label="公司类型">
                  <a-select v-model="form.type">
                    <a-select-option value="0"> 总公司 </a-select-option>
                    <a-select-option value="1"> 分公司 </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item
                  ref="contactName"
                  prop="contactName"
                  label="联系人"
                >
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.contactName"
                  />
                </a-form-model-item>
                <a-form-model-item
                  ref="contactMobile"
                  prop="contactMobile"
                  label="联系人电话"
                >
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.contactMobile"
                  />
                </a-form-model-item>
                <a-form-model-item prop="districtIds" label="地区">
                  <a-cascader
                    style="width: 100%"
                    v-model="form.districtIds"
                    :options="districtlist"
                    :field-names="{
                      label: 'title',
                      value: 'key',
                      children: 'children',
                    }"
                    placeholder="请选择地区"
                    @change="onChange"
                  />
                </a-form-model-item>
                <a-form-model-item
                  ref="address"
                  prop="address"
                  label="详细地址"
                >
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.address"
                  />
                </a-form-model-item>
                <a-form-model-item ref="code" prop="code" label="企业编号">
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.code"
                  />
                </a-form-model-item>
                <a-form-model-item ref="name" prop="name" label="企业名称">
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.name"
                  />
                </a-form-model-item>
                <a-form-model-item
                  ref="shortName"
                  prop="shortName"
                  label="企业简称"
                >
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.shortName"
                  />
                </a-form-model-item>

                <a-form-model-item
                  ref="telephone"
                  prop="telephone"
                  label="座机"
                >
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.telephone"
                  />
                </a-form-model-item>
                <a-form-item :wrapper-col="{ span: 8, offset: 8 }">
                  <a-button type="primary" @click="onSubmit"> 提交 </a-button>
                  <a-button @click="close" style="margin-left: 10px">
                    取消
                  </a-button>
                </a-form-item>
              </a-form-model>
            </div>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '企业名称',
    dataIndex: 'name',
  },
  {
    title: '企业编号',
    dataIndex: 'code',
  },
  {
    title: '企业简称',
    dataIndex: 'shortName',
  },
  {
    title: '联系人',
    dataIndex: 'contactName',
  },
  {
    title: '联系人手机',
    dataIndex: 'contactMobile',
  },
  {
    title: '省份',
    dataIndex: 'province',
  },
  {
    title: '城市',
    dataIndex: 'city',
    scopedSlots: { customRender: 'city' },
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
]
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const OSS = require('ali-oss')
let client
import { nanoid } from 'nanoid'
import { imageMixin } from '../../../mixins'
import http from '../../../http'
export default {
  mixins: [imageMixin],
  data() {
    return {
      display: 'block',
      // 搜索用
      top: '140px',
      expand: 'none',
      formLayout: 'inline',
      wraperCol: { span: 25 },
      logvisible: false,
      loading: false,
      file: null,
      // 行内
      columns,
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      title: '',
      visible: false,
      placement: 'right',
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      // 要传参数
      selectedRowKey: [],
      params: {
        current: 1,
        pageSize: 10,
      },
      total: 0,
      Id: '',
      districtlist: [],
      enterpriselist: [],
      packagelist: {
        total: 0,
        usage: 0,
      },
      value: 'name',
      key: '',
      form: {
        id: '',
        address: '',
        icon: '5.ico',
        contactName: '',
        contactMobile: '',
        type: '0',
        code: '',
        name: '',
        districtId: '',
        districtIds: [],
        shortName: '',
        telephone: '',
        country: '中国',
        city: '',
        state: '',
        province: '',
        logo: [],
      },
      rules: {
        type: [
          {
            required: true,
            message: '公司类型不能为空',
            trigger: 'change',
          },
        ],
        contactName: [
          {
            required: true,
            message: '联系人长度为2-16个字符',
            trigger: 'blur',
            min: 2,
            max: 16,
          },
        ],
        contactMobile: [
          {
            required: true,
            message: '联系人电话格式错误或为空',
            trigger: 'blur',
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          },
        ],
        districtIds: [
          {
            required: true,
            message: '地区不能为空',
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            message: '详细地址不能为空',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '企业编号长度为1-16个字符',
            trigger: 'blur',
            min: 1,
            max: 16,
          },
        ],
        name: [
          {
            required: true,
            message: '企业名称不能为空',
            trigger: 'blur',
          },
        ],
        shortName: [
          {
            required: true,
            message: '企业简称不能为空',
            trigger: 'blur',
          },
        ],
        telephone: [
          {
            required: true,
            message: '固定电话长度为1-16个字符',
            trigger: 'blur',
            min: 1,
            max: 16,
          },
        ],
      },
    }
  },
  filters: {},
  methods: {
    onSearch(value) {
      this.params = {
        current: 1,
        pageSize: 10,
      }
      this.params[this.value] = {
        value: value,
        op: '%like%',
      }
      if (this.value == 'state') {
        this.params[this.value].op = '='
      }
      if (value == '') {
        this.params = {
          current: 1,
          pageSize: 10,
        }
      }
      this.key = ''
      this.getEnterPrise()
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    close() {
      this.Id = ''
      this.form = {
        id: '',
        address: '',
        icon: '5.ico',
        contactName: '',
        contactMobile: '',
        type: '0',
        code: '',
        name: '',
        districtId: '',
        districtIds: [],
        shortName: '',
        telephone: '',
        country: '中国',
        city: '',
        state: '',
        province: '',
        logo: [],
      }
      this.visible = false
    },
    onBack(e) {
      this.Id = ''
      this.visible = false
      this.form.id = e.id
      this.close()
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      //   console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleChanges({ fileList }) {
      if (this.form.logo.length > fileList.length) {
        this.form.logo = fileList
      }
    },
    handleChange(file) {
      var data = file.file
      this.putObject(client, nanoid(), file.file.name.split('.')[1], data)
    },
    async putObject(client, id, name, data) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        let url
        url = client.signatureUrl(result.name)
        var a = [result]
        a.forEach(item => {
          this.form.logo.push({
            bucket: 'sccncloud2',
            name: item.name,
            uid: id,
            status: 'done',
            url: url,
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!')
      }
      return isJpgOrPng && isLt2M
    },
    showDrawer() {
      if (this.packagelist.total == this.packagelist.usage) {
        this.$message.error('您当前套餐,公司数量不足!')
        return
      }
      this.Id == ''
      this.visible = true
      this.title = '新增'
    },
    //项目数量
    async getconfigenterpriserest() {
      try {
        const res = await http.getconfigenterpriserest()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.packagelist = data
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let form = this.form
          form.logo = JSON.stringify(this.form.logo)
          if (this.Id == '') {
            this.Addenterprise(form)
          } else if (this.Id != '') {
            this.putenterprise(form)
          }
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    // 编辑
    onEdit(e) {
      this.visible = true
      //   console.log(e);
      this.Id = e.id
      this.form.id = e.id
      this.getenterpriseid(e.id)
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.stateprise(e.id, this.form.state)
    },
    onpagesize(e) {
      this.params.current = e
      this.getEnterPrise()
      // console.log(this.params.current);
    },
    handleProvinceChange(value) {
      //   console.log(value);
      this.getcity(value)
    },
    // 请求
    async getEnterPrise() {
      try {
        const res = await http.getEnterPrise(this.params)
        this.times = res
        const { success, data } = res.data
        if (success) {
          this.enterpriselist = data.enterprises.records
          this.total = data.enterprises.total
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    // 新增
    async Addenterprise(form) {
      try {
        const res = await http.Addenterprise(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('提交成功')
          this.close()
          this.getconfigenterpriserest()
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    // 编辑
    async putenterprise(form) {
      try {
        const res = await http.putenterprise(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('提交成功')
          this.close()
        } else {
          //   console.log(1);
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    async getenterpriseid(id) {
      try {
        const res = await http.getenterpriseid(id)
        const { success, data } = res.data
        this.timer()
        if (success) {
          //   console.log(data.enterprise);
          this.form = data.enterprise
          this.form.type = data.enterprise.type + ''
          this.form.districtIds = data.enterprise.districtIds
          setTimeout(() => {
            if (data.enterprise.logo == null) {
              this.form.logo = []
            } else {
              this.form.logo = JSON.parse(data.enterprise.logo)
            }

            var a = []
            this.form.logo.forEach(item => {
              let url
              url = client.signatureUrl(item.name)
              a.push({
                bucket: 'sccncloud2',
                name: item.name,
                uid: nanoid(),
                status: 'done',
                url: url,
              })
            })
            this.form.logo = a
          }, 100)
          this.form.districtId = data.enterprise.districtId
          this.visible = true
          this.title = '编辑'
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    // 城市
    onChange(value) {
      //   console.log(value);
      //   this.form.districtId = value;
      this.form.districtId = value[value.length - 1]
    },
    async stateprise(id, state) {
      try {
        const res = await http.stateprise(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    // 删除
    onDetele() {
      let that = this
      this.$confirm({
        title: '确认要删除该公司单位么?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.deleteerprise()
          }, 100)
        },
        onCancel() {
          console.log()
        },
      })
    },
    async deleteerprise() {
      try {
        const res = await http.deleteerprise(this.selectedRowKey)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
          this.timer()
          this.getconfiguserrest()
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    async getdistrict() {
      const res = await http.getdistrict()
      const { success, data } = res.data
      if (success) {
        // console.log(data);
        this.districtlist = data.districts
        this.districtlist.forEach(item => {
          item.children.forEach(item => {
            if (item.children == '') {
              item.children = undefined
            } else {
              item.children.forEach(item => {
                if (item.children == '') {
                  item.children = undefined
                } else {
                  item.children.forEach(item => {
                    if (item.children == '') {
                      item.children = undefined
                    }
                  })
                }
              })
            }
          })
        })
        // console.log(this.districtlist);
      }
    },
    handleClickRow(value) {
      return {
        on: {
          dblclick: () => {
            this.display = 'none'
            // console.log(value);
            this.thing = value
          },
        },
      }
    },
    timer() {
      return setTimeout(() => {
        this.getEnterPrise()
      }, 100)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    onout() {
      this.$router.push({ name: 'login' })
    },
  },
  mounted() {
    this.getEnterPrise()
    this.getconfigenterpriserest()
    this.getsts()
    this.getdistrict()
    const users = JSON.parse(localStorage.getItem('store-userlist'))
    if (users == null) {
      this.logvisible = true
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.ant-upload-picture-card-wrapper {
  margin-bottom: -16px;
}
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-layout {
  height: 100%;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.ant-form-item {
  margin-bottom: 15px;
}

.ant-pagination {
  float: right;
  margin-top: 10px;
}
.packages {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}
</style>
